<template>
  <div class="activity">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="darkred">
      <van-swipe-item v-for="(item, idx) in coverList" :key="idx">
        <img :src="item" />
      </van-swipe-item>
    </van-swipe>
    <div class="activity_info">
      <div class="title">{{ activity.title }}</div>
      <div class="item">
        <span class="label">联系方式：</span><span class="value">{{ activity.contactWay }}</span>
      </div>
      <div class="item">
        <span class="label">人数限制：</span><span class="value">{{ activity.personLimit }} 人/队</span>
      </div>
      <div class="item">
        <span class="label">活动时间：</span><span class="value">{{ activity.startDate }} 至 {{ activity.endDate }}</span>
      </div>
    </div>

    <div v-if="teamId<0" class="as-leader">
      <div class="tips">组队成功后，您将是队长。</div>
    </div>
    <div v-else class="team-members">
      <div class="title">队员列表</div>
      <van-grid :column-num="5" :border="false">
        <van-grid-item v-for="(item,idx) in memberList" :key="idx">
          <van-badge v-if="item.leaderFlag" :content="'队长'">
            <img :src="item.avatar" class="avatar" />
          </van-badge>
          <img v-else :src="item.avatar" class="avatar" />
        </van-grid-item>
      </van-grid>
    </div>

    <div class="ext-form-box">
      <!--扩展表单信息-->
      <van-cell-group>
        <div v-for="(item,idx) in formItemList" :key="idx">
          <van-field v-if="item.itemType==='text'" v-model="extDataForm[item.id]" :label="item.title" :placeholder="'请填写'+item.title"
            :maxlength="item.maxLength" />
          <van-field v-if="item.itemType==='tel'" v-model="extDataForm[item.id]" type="tel" :label="item.title" :placeholder="'请填写'+item.title"
            :maxlength="item.maxLength" />
          <van-field v-if="item.itemType==='number'" v-model="extDataForm[item.id]" type="number" :label="item.title" :placeholder="'请填写'+item.title"
            :maxlength="item.maxLength" />
          <van-field v-if="item.itemType==='select'" v-model="extDataForm[item.id]" :label="item.title" clickable readonly :placeholder="'请选择'+item.title"
            @click="handleShowPicker(item)" />
          <van-field v-if="item.itemType==='radio'" :name="item.id" :label="item.title">
            <template #input>
              <van-radio-group v-model="extDataForm[item.id]" direction="horizontal">
                <van-radio v-for="rd in getRadioOptions(item.id)" :key="rd.id" :name="rd.id">{{ rd.label }}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
      </van-cell-group>
    </div>

    <!--底部弹出选项列表-->
    <van-popup v-model="showPicker" get-container="body" position="bottom">
      <van-picker title="可选项" show-toolbar :columns="pickerColumns" value-key="label" :default-index="0" @confirm="onConfirmPickerItem" @cancel="onCancelPicker"
        @change="onChangePickerItem" />
    </van-popup>

    <!--海报生成按钮-->
    <div v-if="activity.activityStatus === 0 && teamId>0" class="share_wrapper" @click="openSharePic">邀请好友</div>
    <van-popup v-model="showSharePic" class="share-pic">
      <van-loading v-if="loading" type="spinner" size="24px">正在生成海报</van-loading>
      <img v-else ref="posterImg" :src="posterImg" />
    </van-popup>

    <!-- 当前登录：{{ userInfo.nickname }}
    <img :src="userInfo.avatar" style="width: 50px; border-radius: 50%" />
    loc:{{ loc }}<br />
    initLink:{{ initLink }} -->

    <van-goods-action v-if="activity.activityStatus === 0">
      <!--活动可用-->
      <van-goods-action-button type="primary" text="团队列表" @click="toAllTeam" />
      <!--新建团队-->
      <van-goods-action-button v-if="teamId<0" type="danger" text="确认组队" @click="handleCreateOrJoinTeam" />
      <!--加入团队-->
      <van-goods-action-button v-else type="danger" text="加入此队" @click="handleCreateOrJoinTeam" />
    </van-goods-action>
    <van-goods-action v-else>
      <!--活动其他状态-->
      <van-goods-action-button v-if="activity.activityStatus === -1" type="danger" text="活动未开始" />
      <van-goods-action-button v-if="activity.activityStatus === 1" type="danger" text="活动已结束" />
    </van-goods-action>

  </div>
</template>
<script>
import freeTeamApi from '@/api/free-team'
import activityApi from '@/api/activity'
export default {
  data() {
    return {
      appId: '',
      teamId: -1,
      activity: {
        id: '',
        title: '',
        descn: ''
      },
      showPicker: false,

      pickerColumns: [],
      allPickerColumns: [],
      allRadioOptions: [],
      curTeam: {},
      curFormItem: {},
      extDataForm: {}, // 扩展信息表单
      memberList: [],
      formItemList: [],
      coverList: [],
      showSharePic: false, // 显示海报
      posterImg: '', // 海报图片
      loading: false, // 海报加载
      imgServer: process.env.VUE_APP_IMG_SERVER_URL
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.teamId = parseInt(this.$route.params.teamId)
    this.activity.id = this.$route.params.id
  },
  mounted() {
    this.initActivityData()
    this.initTeamData()
  },
  methods: {
    onConfirmPickerItem(value, index) {
      // this.$toast(`当前值：${JSON.stringify(value)}, 当前索引：${index}`)
      this.extDataForm[this.curFormItem.id] = value.label
      this.showPicker = false
    },
    onChangePickerItem(picker, value, index) {
      // this.$toast(`当前值：${JSON.stringify(value)}, 当前索引：${index}`)
      this.extDataForm[this.curFormItem.id] = value.label
    },
    onCancelPicker() {
      this.showPicker = false
    },
    // 校验表单信息
    validateExtDataForm() {
      let keyArr = Object.keys(this.extDataForm)
      let formItemIdArr = []
      this.formItemList.forEach(i => {
        formItemIdArr.push(i.id)
      })
      // 校验字段必填
      for (let i = 0; i < formItemIdArr.length; i++) {
        if (keyArr.indexOf(formItemIdArr[i]) === -1) {
          let formItem = this.getFormItemById(formItemIdArr[i])
          if (formItem.itemType === 'select' || formItem.itemType === 'radio') {
            this.$toast('请选择' + formItem.title)
            return false
          } else {
            this.$toast('请填写' + formItem.title)
            return false
          }
        }
      }
      return true
    },
    // 显示图片分享层
    openSharePic() {
      this.showSharePic = true
      this.loading = true
      activityApi.genSharePic(this.activity.id).then(res => {
        this.loading = false
        const a = new FileReader()
        a.readAsDataURL(res)
        a.onload = e => {
          this.posterImg = e.target.result
        }
      })
    },
    handleCreateOrJoinTeam() {
      if (this.validateExtDataForm()) {
        // 校验表单成功
        for (let key in this.extDataForm) {
          // 主要用于替换“文本内容”为“值”，用于Select类型
          let val = this.getPickerValByKeyAndText(key, this.extDataForm[key])
          if (val) {
            this.extDataForm[key] = val
          }
        }
        console.log(JSON.stringify(this.extDataForm))

        let data = {
          activityId: this.activity.id,
          teamId: this.teamId,
          extData: JSON.stringify(this.extDataForm)
        }
        freeTeamApi.create(data).then(res => {
          if (res.succ) {
            this.$toast({
              type: 'success',
              message: '操作成功',
              onClose: () => {
                // 跳转到teamList
                this.$router.push(`/${this.appId}/freeTeam/teamList/${this.activity.id}`)
              }
            })
          }
        })
      }
    },
    getFormItemById(id) {
      for (let i = 0; i < this.formItemList.length; i++) {
        if (this.formItemList[i].id === id) {
          return this.formItemList[i]
        }
      }
    },
    // 根据input中的文本，取得实际的val
    getPickerValByKeyAndText(key, text) {
      for (let i = 0; i < this.allPickerColumns.length; i++) {
        if (this.allPickerColumns[i].key === key) {
          let columns = this.allPickerColumns[i].columns
          for (let j = 0; j < columns.length; j++) {
            if (columns[j].label === text) {
              return columns[j].id
            }
          }
        }
      }
    },
    handleShowPicker(item) {
      this.curFormItem = item
      this.pickerColumns = this.getFromAllPickerColumns(item)
      this.showPicker = true
    },
    getFromAllPickerColumns(item) {
      // 从全部picker中取可选项
      for (let i = 0; i < this.allPickerColumns.length; i++) {
        let pc = this.allPickerColumns[i]
        if (pc.key === item.id) {
          // 有，就直接返回
          return pc.columns
        }
      }
    },
    toAllTeam() {
      this.$router.push(`/${this.appId}/freeTeam/teamList/${this.activity.id}`)
    },
    // 加载活动信息
    initActivityData() {
      freeTeamApi.get(this.activity.id).then(res => {
        if (res.succ) {
          let data = res.data
          if (!data.item) {
            this.$toast('组队活动不存在')
          } else {
            this.activity = data.item
            this.formItemList = data.formItemList
            this.initBanners(data)
            this.initAllPickerColumns(data)
            this.initAllRadioOptions(data)
          }
        }
      })
    },
    // 加载团队&队员信息
    initTeamData() {
      if (this.teamId > 0) {
        freeTeamApi.getTeam(this.activity.id, this.teamId).then(res => {
          if (res.succ) {
            this.curTeam = res.data.item
            this.memberList = res.data.items
          }
        })
      }
    },
    // 初始化banners
    initBanners(data) {
      if (data.banners) {
        data.banners.forEach(i => {
          this.coverList.push(this.imgServer + '/' + i.filePath)
        })
      }
    },
    // 根据item,取得options
    getRadioOptions(itemId) {
      for (let i = 0; i < this.allRadioOptions.length; i++) {
        if (this.allRadioOptions[i].key === itemId) {
          return this.allRadioOptions[i].options
        }
      }
    },
    // 初始化radio选项
    initAllRadioOptions(data) {
      if (this.formItemList) {
        this.formItemList.forEach(i => {
          if (i.itemType === 'radio') {
            let npc = {
              key: i.id,
              options: JSON.parse(i.defaultValue)
            }
            this.allRadioOptions.push(npc)
          }
        })
      }
    },
    // 初始化下拉选项
    initAllPickerColumns(data) {
      if (this.formItemList) {
        this.formItemList.forEach(i => {
          if (i.itemType === 'select') {
            let npc = {
              key: i.id,
              columns: JSON.parse(i.defaultValue)
            }
            this.allPickerColumns.push(npc)
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.activity {
  background-color: #fff;
  .my-swipe {
    height: 280px;
    img {
      width: 100%;
      height: 280px;
    }
  }
  .activity_info {
    padding: 0.2rem;
    font-size: 0.4rem;
    .title {
      text-align: center;
      font-size: 0.6rem;
      padding: 0.2rem;
    }
    .item {
      height: 0.8rem;
      line-height: 0.8rem;
      .label {
        width: 1rem;
      }
      .value {
        color: #999;
        width: 1rem;
        text-align: left;
      }
    }
  }
  .as-leader {
    background-color: #fff;
    padding: 0 0.2rem;
    .tips {
      font-size: 0.4rem;
      color: darkred;
      padding: 0 0.2rem;
      border: 1px dashed darkred;
    }
  }
  .team-members {
    background-color: #fff;
    padding: 0 0.2rem;
    .title {
      border-left: 8px solid darkred;
      font-size: 0.4rem;
      font-weight: 500;
      padding-left: 0.1rem;
      margin-bottom: 0.2rem;
    }
    .van-grid {
      border: 1px dashed darkred;
      min-height: 1.6rem;
      img.avatar {
        width: 1.2rem;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
  }
}
.ext-form-box {
  background-color: #fff;
  padding-top: 0.2rem;
  margin-bottom: 1rem;
}
.share_wrapper {
  position: fixed;
  right: 0;
  top: 36vh;
  right: 2vh;
  display: block;
  background-color: #7232dd;
  color: #fff;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  padding: 2px 2px 2px 6px;
  font-size: 14px;
  font-weight: bold;
}
.van-popup.share-pic {
  width: 84%;
  min-height: 60vh;
  // overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .van-loading {
    margin: 0 auto;
  }
  img {
    width: 100%;
    display: block;
  }
}
</style>
